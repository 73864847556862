import { ref, computed } from 'vue';
import { CategorySchema } from '@/api';
import { CategoryService } from '@/services';

const data = ref<CategorySchema[]>([]);

export function useCategory() {
    const fetch = async () => {
        const categories = await CategoryService.all();
        data.value = categories;
    };

    function tree(items: CategorySchema[]): CategorySchema[] {
        const root: CategorySchema[] = [];
        const lookup: { [k: string]: CategorySchema } = {};
        items.forEach((item) => (lookup[item.id] = { ...item, children: [] }));
        items.forEach((item) => {
            if (item.parent_id) lookup[item.parent_id].children?.push(lookup[item.id]);
            else root.push(lookup[item.id]);
        });
        return root;
    }

    const categories = computed(() => {
        const items = clone(data.value);
        return tree(items);
    });

    const getCategory = (id: string) => {
        return data.value.find((x) => x.id == id);
    };

    const getCategoryPath = (id: string) => {
        let c = getCategory(id);
        let path = [];
        while (c) {
            path.push(c.title);
            c = c.parent_id ? getCategory(c.parent_id) : undefined;
        }
        return path.reverse();
    };

    return {
        categories,
        getCategoryPath,
        fetch,
    };
}
