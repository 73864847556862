import axios from 'axios';
import router from '@/router';
import bus from './bus';
import { useToast } from '@/composables';

const MAX_CONCURRENCY = 2;
let running = 0;

const http = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/api`,
    timeout: 60 * 4 * 1000,
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
    },
});

http.interceptors.request.use((config) => {
    return new Promise((resolve) => {
        const auth = useAuthStore();

        if (running < MAX_CONCURRENCY) {
            running++;
            document.body.classList.add('is-busy');
            if (!config.headers) config.headers = {};
            if (auth.token) {
                config.headers['Authorization'] = 'Bearer ' + auth.token;
            }
            return resolve(config);
        }

        function acquire() {
            if (running < MAX_CONCURRENCY) {
                running++;
                document.body.classList.add('is-busy');
                if (!config.headers) config.headers = {};
                if (auth.token) {
                    config.headers['Authorization'] = 'Bearer ' + auth.token;
                }
                bus.off('http:release', acquire);
                return resolve(config);
            }
        }
        bus.on('http:release', acquire);
    });
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value);
        else if (typeof value === 'object') handleDates(value);
    }
}

http.interceptors.response.use(
    (response) => {
        document.body.classList.remove('is-busy');
        // handleDates(response.data);
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return response;
    },
    (error) => {
        document.body.classList.remove('is-busy');
        const toast = useToast();
        const auth = useAuthStore();
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    toast.error('Your session is inactive. Please login again.');
                    auth.signout();
                    router.replace({ path: '/login', query: { continue: window.location.pathname } });
                    break;
                case 422:
                    toast.error('Invalid data.');
                    break;
                case 500:
                    toast.error('Something went wrong.');
                    break;
                case 404:
                    toast.error('Resource not found.');
                    break;
                case 403:
                    toast.error('You do not have proper permission to access this resource.');
                    router.replace('/');
                    break;
                default:
                    toast.error(error.response.data?.error || error.response.data?.message || error.toString());
            }
        } else if (error.request) {
            toast.error('Could not connect to server.');
        } else {
            toast.error('Something went wrong.');
        }
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return Promise.reject(error);
    }
);

export default http;
