import { useAuthStore } from '@/stores/auth';
import axios, { AxiosRequestConfig } from 'axios';
import { useToast } from './use-toast';
import router from '@/router';

export function useHttp<T extends any>(url?: string | Ref<string>) {
    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_URL}/api`,
        timeout: 60 * 4 * 1000,
    });
    client.interceptors.request.use((config) => {
        return new Promise((resolve) => {
            const auth = useAuthStore();
            if (!config.headers) config.headers = {};
            if (auth.token) {
                config.headers['Authorization'] = 'Bearer ' + auth.token;
            }
            return resolve(config);
        });
    });

    const processing = ref(false);
    const result = ref<T>();
    const errors = ref<Record<string, string>>({});

    async function exec(config?: string | AxiosRequestConfig) {
        let opts: AxiosRequestConfig = {};
        if (config) {
            opts = typeof config === 'string' ? { url: config, method: 'get' } : { ...config };
        } else {
            opts = url ? { url: unref(url), method: 'get' } : {};
        }
        try {
            processing.value = true;
            errors.value = {};
            const response = await client.request<T>(opts);
            result.value = response.data;
        } catch (error: any) {
            const toast = useToast();
            const auth = useAuthStore();
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        toast.error('Your session is inactive. Please login again.');
                        auth.signout();
                        router.replace({ path: '/login', query: { continue: window.location.pathname } });
                        break;
                    case 422:
                        toast.error('Invalid data.');
                        break;
                    case 500:
                        toast.error('Something went wrong.');
                        break;
                    case 404:
                        toast.error('Resource not found.');
                        break;
                    case 403:
                        router.replace('/');
                        toast.error('You do not have proper permission to access this resource.');
                        break;
                    default:
                        toast.error(error.response.data?.error || error.response.data?.message || error.toString());
                }
            } else if (error.request) {
                toast.error('Could not connect to server.');
            } else {
                toast.error('Something went wrong.');
            }
            return Promise.reject(error);
        } finally {
            processing.value = false;
        }
        return result.value;
    }

    if (url) {
        if (isRef(url)) watch(url, exec);
        if (unref(url)) exec();
    }

    return {
        processing,
        result,
        errors,
        exec,
    };
}
