import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import customerRoutes from './customer';
import userRoutes from './user';
import staffRoutes from './staff';
import jobRoutes from './job';
import locationRoutes from './location';
import categoryRoutes from './category';
import partnerRoutes from './partner';
import enquiryRoutes from './enquiry';
import addressRoutes from './address';
import settingRoutes from './setting';
import reportRoutes from './report';
import { useToast } from '@/composables';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/vue';

export const routerHistory = createWebHistory();

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        redirect: { name: 'dashboard' },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard/Index.vue'),
    },
    ...categoryRoutes,
    ...customerRoutes,
    ...userRoutes,
    ...partnerRoutes,
    ...jobRoutes,
    ...enquiryRoutes,
    ...locationRoutes,
    ...staffRoutes,
    ...addressRoutes,
    ...settingRoutes,
    ...reportRoutes,
    {
        path: '/future',
        name: 'future',
        component: () => import('@/views/Future/Index.vue'),
    },
    {
        path: '/cache',
        name: 'cache.index',
        component: () => import('@/views/Cache/Index.vue'),
    },
    {
        path: '/queue',
        name: 'queue.index',
        component: () => import('@/views/Queue/Index.vue'),
    },
    {
        path: '/query',
        name: 'query.index',
        component: () => import('@/views/Query/Index.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: { guest: true, layout: 'blank' },
    },
    {
        path: '/down',
        name: 'down',
        component: () => import('@/views/Down.vue'),
        meta: { guest: true, layout: 'blank' },
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound.vue'),
        meta: { guest: true, layout: 'blank' },
    },
];

const router = createRouter({
    routes,
    history: routerHistory,
});

router.beforeEach((to) => {
    const auth = useAuthStore();

    // Identify user in LogRocket
    if (auth.isLoggedIn) {
        LogRocket.identify(auth.user!.id, {
            name: auth.user!.name,
        });
        Sentry.setUser({ id: auth.user!.id, name: auth.user!.name });
    } else {
        Sentry.setUser(null);
    }

    const { guest = false, roles = null } = to.meta;

    if (!guest && !auth.isLoggedIn) {
        return { path: '/login', replace: true, query: { continue: to.path } };
    }

    if (roles && !auth.is(roles as string)) {
        const toast = useToast();
        toast.error('Permission denied', 'You do not have permission to access this page.');
        return '/dashboard';
    }

    if (to.path == '/login' && auth.isLoggedIn) {
        return { path: '/dashboard', replace: true };
    }
});

export default router;
