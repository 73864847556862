import { CategorySchema } from '@/api';
import http from '@/js/http';
import { ICategoryCreateRequest } from './request.interface';

export default {
    async get(id: string) {
        const { data } = await http.get<CategorySchema>(`/categories/${id}`);
        return data;
    },

    async all() {
        const { data } = await http.get<CategorySchema[]>(`/categories`);
        return data;
    },

    async create(payload: ICategoryCreateRequest) {
        const { data } = await http.post<CategorySchema>(`/categories`, payload);
        return data;
    },

    async update(id: string, payload: ICategoryCreateRequest) {
        const { data } = await http.put<CategorySchema>(`/categories/${id}`, payload);
        return data;
    },
};
